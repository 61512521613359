import React from "react";

const TermsPage = () => {
  return (
    <div style={{ height: '100vh', width: '100vw', margin: 0, padding: 0, overflow: 'hidden' }}>
      <iframe
        src="https://www.termsfeed.com/live/72b70b75-2ebb-42a4-b87d-f3cf0e5bfdc8"
        style={{ height: '100%', width: '100%', border: 'none' }}
        title="Terms and Conditions"
      />
    </div>
  );
};

export default TermsPage;
